<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g transform="translate(-266 -393)">
      <g class="a" transform="translate(266 393)">
        <circle class="c" cx="9" cy="9" r="9" />
        <circle class="d" cx="9" cy="9" r="8.5" />
      </g>
      <g transform="translate(271.247 397.497)">
        <path
          class="b"
          d="M8.245,0H0"
          transform="translate(3.748 0) rotate(90)"
        />
        <path
          class="b"
          d="M0,7.5,3.748,3.747,0,0"
          transform="translate(7.495 4.497) rotate(90)"
        />
      </g>
    </g>
  </svg>
</template>



<script>
export default {
  name: "Download",
};
</script>

<style lang="scss" scoped>
.a,
.b,
.d {
  fill: none;
}
.a,
.b {
  stroke: #2d323c;
}
.b {
  stroke-miterlimit: 10;
}
.c {
  stroke: none;
}
</style>