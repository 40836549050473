<template>
  <div
    class="context-menu"
    :class="{ 'mobile-full-width': icon == 'button' && deviceWidth < 992 }"
  >
    <span
      :title="title"
      class="icon"
      ref="icon"
      :class="{ loading }"
      @click.stop="openMenu()"
    >
      <Download v-if="icon == 'download'" />
      <p v-else-if="icon == 'text'" class="icon-text">{{ iconText }}</p>
      <button class="context-button" v-else-if="icon == 'button'">
        {{ iconText }}
      </button>
    </span>

    <transition name="menufade">
      <div class="menu-container" ref="tooltip" v-if="showMenu">
        <div class="menu-title" v-if="title">
          <p>
            <b>{{ title }}</b>
          </p>
        </div>
        <ul>
          <li
            class="menu-item"
            v-for="item in menuItems"
            :class="{ disabled: item.disabled }"
            @mouseup="action(item)"
          >
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { createPopper } from "@popperjs/core";
import Download from "@/assets/icons/Download";

export default {
  name: "TjingContextMenu",
  props: ["title", "menuItems", "icon", "iconText", "position", "loading"],
  components: { Download },
  data() {
    return {
      showMenu: false,
      popperInstance: null,
    };
  },
  directives: {
    ClickOutside,
  },
  watch: {
    escapePressed() {
      this.closeMenu();
    },
  },
  computed: {
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    escapePressed() {
      return this.$store.getters.escapePressed;
    },
  },
  methods: {
    action(item) {
      if (item.disabled) return;

      if (item.payload) {
        this.$emit(item.action, item.payload);
      } else {
        this.$emit(item.action);
      }

      this.closeMenu();
    },
    openMenu() {
      document.addEventListener("click", this.clickEvent);

      if (this.showMenu) {
        this.closeMenu();
      } else {
        this.showMenu = true;

        if (this.icon !== "button" || this.deviceWidth > 992) {
          this.$nextTick(() => {
            const button = this.$refs.icon;
            const tooltip = this.$refs.tooltip;

            this.popperInstance = createPopper(button, tooltip, {
              placement: this.position,
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    mainAxis: true,
                    padding: { right: 0, left: 0 },
                  },
                },
                {
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                },
              ],
            });
          });
        }
      }
    },
    clickEvent(e) {
      //var element = document.getElementById("parent-node");
      var element = this.$refs.icon;
      if (e.target !== element && !element?.contains(e.target)) {
        this.closeMenu();
      }
    },
    closeMenu() {
      this.showMenu = false;

      document.removeEventListener("click", this.clickEvent);

      setTimeout(() => {
        if (this.popperInstance != null) {
          this.popperInstance.destroy();
          this.popperInstance = null;
        }
      }, 300);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.context-button {
  border: none;
  background: $cloud;
  @include Gilroy-Bold;
  height: 48px;
  font-size: 16px;
  transition: all ease 0.3s;
  width: 100%;

  &:hover {
    background: $fog;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
.context-menu {
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: relative;

  &.mobile-full-width {
    width: 100%;
    .icon {
      width: 100%;
    }

    .menu-container {
      left: 0;
      top: calc(100% + 12px);
      width: 100%;
    }
  }
  .icon {
    cursor: pointer;
    display: inline-flex;
    overflow: visible;

    &.loading {
      opacity: 0.8;
      animation: pulse 1.5s infinite;
    }

    svg {
      transition: all ease 0.3s;
    }
  }

  .icon-text {
    margin: 0;
    color: $ocean;
    @include Gilroy-Bold;
    line-height: 1.4em;
  }
}

.menu-container {
  position: absolute;
  top: 0;
  left: 0;

  &.right {
    right: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-container {
  background: white;
  border: 1px solid $sleet;
  border-radius: 6px;

  :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .menu-title {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid $sleet;
    padding: 12px 18px;
    user-select: none;
  }

  .menu-item {
    padding: 12px 18px 12px 18px;
    transition: all ease 0.3s;
    user-select: none;
    &:hover {
      background: $cloud;
      cursor: pointer;
    }

    p {
      white-space: nowrap;
    }

    &.disabled {
      color: $blizzard;
      &:hover {
        background: white;
        cursor: default;
      }
    }
  }
}

.menufade-enter-active,
.menufade-leave-active {
  transition: opacity 0.3s;
}
.menufade-enter, .menufade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .context-menu {
    &.mobile-full-width {
      width: auto;
      .icon {
        width: auto;
      }
      .menu-container {
        width: 100%;
        max-width: 100%;
      }
    }
    .icon {
      cursor: pointer;

      svg {
        transform: scale(1.12);
      }
    }
  }
}
</style>
